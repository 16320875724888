import React, { Fragment } from 'react'
import { gql } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import {
  Button,
  isBrowser,
  match,
  P,
  Section,
  ThemeProvider,
  tracking,
} from '@klickmarketing/react-components'
import { Box, Container, Grid } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link as I18nLink, useI18next } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import { IDX_FLOODLIGHT_TAGS } from '../../config'
import { DEFAULT_LOCALE, LOCALES } from '../../languages'
import { formatPublishDate, getSocialThumbPath } from '../common/utils'
import ContactUs from '../components/ContactUs/ContactUs'
import IdxPlayer from '../components/IdxPlayer/IdxPlayer'
import Layout from '../components/Layout/Layout'
import MediaTile from '../components/MediaTile/MediaTile'
import VideoContent from '../components/VideoContent/VideoContent'
import { useQueryLatestData } from '../hooks/useQueryLatestData'

const VideoTemplate = ({ id }) => {
  const { language, t } = useI18next()
  const { loginWithRedirect, isLoading: isAuthLoading } = useAuth0()
  const { latestData, loading: isContentFetching } = useQueryLatestData(
    GET_VIDEO_DATA,
    {
      variables: {
        id,
        locale: language || DEFAULT_LOCALE,
        allLocales: Object.values(LOCALES),
      },
    }
  )

  const { backdrop } = useStaticQuery(graphql`
    {
      backdrop: file(relativePath: { eq: "Video/backdrop.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: NONE
            breakpoints: [630, 900, 1350, 1920]
            sizes: "(min-width: 1280px) 1230px, 100vw"
          )
        }
      }
    }
  `)

  React.useEffect(() => {
    tracking.track({
      dcCode: IDX_FLOODLIGHT_TAGS.PAGE_LOAD_VIDEO,
    })
  }, [])

  React.useEffect(() => {
    if (!latestData) return
    // if there are no results retrieved send to a 404 page
    if (latestData.videoPostCollection.items.length === 0) {
      window.location = '/not-found'
    }
  }, [latestData])

  const returnTo = isBrowser && window.location.pathname
  const loginOnClick = () =>
    loginWithRedirect({
      appState: {
        returnTo,
      },
    })

  const video = latestData?.videoPostCollection.items[0]
  const AuthOrContentLoading = isAuthLoading || isContentFetching
  const isAllowedToView = !AuthOrContentLoading && !!video?.videoAsset?.videoUri
  const collection = video?.linkedFrom?.collectionCollection.items?.[0]
  const baseUrl = video?.videoAsset?.videoUri?.substring(
    0,
    video?.videoAsset?.videoUri?.lastIndexOf('/')
  )
  const transcriptUrl = baseUrl ? `${baseUrl}/transcript.txt` : null
  const captionsUrl = baseUrl ? `${baseUrl}/captions.vtt` : null
  const thumbsUrl = baseUrl
    ? `${baseUrl}/hls-1440_Thumbnail_I-Frame.m3u8`
    : null

  const posterImage = video?.videoAsset?.thumbnail.url
  const socialThumbPath = getSocialThumbPath(
    video,
    video?.publishDate
      ? {
          d: formatPublishDate(video.publishDate, language),
        }
      : {}
  )
  const seoProps = !video
    ? { title: t(`Videos`, { ns: 'videos' }) }
    : {
        title: video.title,
        description: video.description,
        type: 'video.other',
        socialThumbPath,
      }

  return (
    <Layout seoProps={seoProps}>
      <Box minHeight="100vh">
        <ThemeProvider themeType="onWhite">
          <StyledSection name="Video">
            <StyledImage
              image={getImage(backdrop)}
              role="presentation"
              alt=""
            />
            <Box position="relative" py={8}>
              <VideoBlock maxWidth="lg">
                <VideoContainer>
                  {AuthOrContentLoading || isAllowedToView ? (
                    <IdxPlayer
                      title={video?.title}
                      file={video?.videoAsset.videoUri}
                      thumbnail={video?.videoAsset?.thumbnail?.url}
                      captionsUrl={captionsUrl}
                      transcriptUrl={transcriptUrl}
                      thumbsUrl={thumbsUrl}
                      onPercentageWatched={({ percentageWatched }) => {
                        if (0 <= percentageWatched < 50) {
                          tracking.track({
                            dcCode: IDX_FLOODLIGHT_TAGS.PLAY_VIDEO_0_PERCENT,
                          })
                        }
                        if (percentageWatched >= 50) {
                          tracking.track({
                            dcCode: IDX_FLOODLIGHT_TAGS.PLAY_VIDEO_50_PERCENT,
                          })
                        }
                      }}
                      languages={Object.values(LOCALES)}
                      defaultLanguage={'en-US'}
                    />
                  ) : (
                    <ProtectedBoxContainer>
                      <ProtectedBox $posterImage={posterImage}>
                        <Box
                          sx={{
                            display: { xs: 'none', sm: 'block' },
                          }}
                        >
                          <Button onClick={loginOnClick} size="xxlarge">
                            {t('Sign Up or Log in to View', { ns: 'common' })}
                          </Button>
                        </Box>
                        <Box
                          sx={{
                            display: { xs: 'block', sm: 'none' },
                          }}
                        >
                          <Button onClick={loginOnClick} size="large">
                            {t('Sign Up or Log in to View', { ns: 'common' })}
                          </Button>
                        </Box>
                      </ProtectedBox>
                    </ProtectedBoxContainer>
                  )}
                </VideoContainer>
              </VideoBlock>
            </Box>
            <Container maxWidth="lg">
              <Box
                maxWidth="calc(100%)"
                sx={{
                  overflow: 'hidden',
                  paddingBottom: '100px',
                }}
              >
                <Grid container spacing={8}>
                  <Grid item xs={12} md={7}>
                    <VideoContent video={video} />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Box my={2}>
                      <P variant="blurb2">
                        <b>{t('Related Content', { ns: 'videos' })}</b>
                      </P>
                    </Box>
                    {!latestData
                      ? Array.apply(null, new Array(3)).map((o, i) => (
                          <Fragment key={i}>
                            <hr />
                            <MediaTile isLoading={true} variant="dense" />
                          </Fragment>
                        ))
                      : video?.relatedMediaCollection?.items.map(
                          (item, index) => (
                            <Fragment key={index}>
                              <hr />
                              <MediaTile {...item} variant="dense" />
                            </Fragment>
                          )
                        )}
                    {collection && (
                      <Box textAlign="left" pt={6}>
                        <Button
                          component={I18nLink}
                          to={`/collections/${collection.slug}`}
                        >
                          {`View more from the ${collection.title} Collection`}
                        </Button>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </StyledSection>
        </ThemeProvider>
      </Box>
      <ThemeProvider themeType="onBlack">
        <ContactUs />
      </ThemeProvider>
    </Layout>
  )
}

const GET_VIDEO_DATA = gql`
  query GetVideoData(
    $id: String!
    $locale: String!
    $preview: Boolean
    $allLocales: [String]!
  ) {
    videoPostCollection(
      where: { slug: $id }
      limit: 1
      locale: $locale
      preview: $preview
    ) {
      items {
        linkedFrom(allowedLocales: $allLocales) {
          eventCollection {
            items {
              title
              slug
            }
          }
          collectionCollection(limit: 1) {
            items {
              title
              description
              slug
            }
          }
        }
        contentfulMetadata {
          tags {
            id
            name
          }
        }
        title
        publishDate
        description
        videoAsset {
          videoUri
          thumbnail {
            url
          }
        }
        relatedMediaCollection {
          items {
            __typename
            ... on VideoPost {
              title
              slug
              videoAsset {
                duration
                thumbnail {
                  url
                }
              }
              linkedFrom(allowedLocales: $allLocales) {
                collectionCollection(limit: 10) {
                  items {
                    title
                  }
                }
                eventCollection(limit: 10) {
                  items {
                    title
                  }
                }
              }
              personsCollection(limit: 20) {
                items {
                  name
                }
              }
              tagsCollection(limit: 40) {
                items {
                  name
                }
              }
            }
            ... on ArticlePost {
              title
              slug
              heroImage {
                focalPoint
                image {
                  url
                  description
                  width
                  height
                }
              }
              linkedFrom(allowedLocales: $allLocales) {
                collectionCollection(limit: 10) {
                  items {
                    title
                  }
                }
                eventCollection(limit: 10) {
                  items {
                    title
                  }
                }
              }
              personsCollection(limit: 20) {
                items {
                  name
                }
              }
              tagsCollection(limit: 40) {
                items {
                  name
                }
              }
            }
          }
        }
        tagsCollection {
          items {
            name
            sys {
              id
            }
          }
        }
        personsCollection(limit: 4) {
          items {
            sys {
              id
            }
            name
            credentials
          }
        }
      }
    }
  }
`

const ProtectedBoxContainer = styled.div`
  aspect-ratio: 16 / 9;
`

// const StyledButton = styled(Button)`
//   color: ${({ theme }) => theme.palette.primary.main};
// `

const ProtectedBox = styled(Box)`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  background-image: url(${({ $posterImage }) => $posterImage});
  background-size: cover;

  & > * {
    z-index: 2;
  }

  &:after {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    content: '';
    background: rgb(0 0 0 / 60%);
    z-index: 1;
  }
`

const VideoBlock = styled(Container)`
  padding-left: 0px;
  padding-right: 0px;

  ${match.isSM} {
    padding-left: 24px;
    padding-right: 24px;
  }
`

const VideoContainer = styled(Box)`
  position: relative;
  background: #000000;
  background: white;
  width: 100%;
  height: fit-content;
`

const StyledSection = styled(Section)`
  position: relative;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 58px;

  ${match.isSM} {
    margin-top: 64px;
  }

  ${match.isMD} {
    margin-top: 84px;
  }
`

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  max-height: 500px;
  position: absolute;
  top: 0px;
  left: 0px;
`
export default VideoTemplate
