import React from 'react'
import { match, VideoPlayer } from '@klickmarketing/react-components'
import { Skeleton } from '@material-ui/lab'
import styled from 'styled-components'

const IdxPlayer = (props) => {
  if (!props.file) {
    return (
      <SkeletonContainer>
        <Skeleton variant="rect" width="100%" height="100%" />
      </SkeletonContainer>
    )
  }

  return <VideoPlayer {...props} />
}

const SkeletonContainer = styled.div`
  aspect-ratio: 613 /544;
  ${match.isMD} {
    aspect-ratio: 1235 /500;
  }
`

export default IdxPlayer
