import React from 'react'
import { Heading, P, TextLink } from '@klickmarketing/react-components'
import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { Link as I18nLink, useI18next } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import { formatPublishDate } from '../../common/utils'
import LayoutCopy from '../_articles/LayoutCopy'
import TagList from '../TagList/TagList'

const VideoContent = ({ video }) => {
  const { language, t } = useI18next()
  if (!video) {
    return <VideoContentSkeleton />
  }
  const { description, publishDate } = video
  const speakers = video.personsCollection.items
  const tags = video?.tagsCollection?.items
  const relatedEvents = video?.linkedFrom?.eventCollection?.items

  return (
    <>
      <Box my={2}>
        <StyledHeading component="h1" variant="h4">
          {video.title}
        </StyledHeading>
      </Box>
      <Box mt={2}>
        <P variant="blurb2">
          {speakers.map((speaker, index) => (
            <React.Fragment key={`speaker-${speaker.name}`}>
              <TextLink
                to={`/discover?people=${encodeURIComponent(speaker.name)}`}
                component={I18nLink}
              >
                {speaker.name}
              </TextLink>
              {index !== speakers.length - 1 ? ', ' : null}
            </React.Fragment>
          ))}
        </P>
      </Box>
      {relatedEvents?.[0] ? (
        <P variant="blurb2" component="div">
          <b>
            <Box
              component="span"
              sx={{
                display: 'flex',
                gap: 8,
                flexDirection: { xs: 'column', md: 'row' },
              }}
            >
              <Box component="span">
                <TextLink
                  to={`/discover?event=${encodeURIComponent(
                    relatedEvents[0].title
                  )}`}
                  component={I18nLink}
                >
                  {relatedEvents[0].title}
                </TextLink>{' '}
                <Box
                  component="span"
                  sx={{
                    display: { xs: 'none', md: 'inline' },
                  }}
                >
                  |
                </Box>
              </Box>
              <span>{formatPublishDate(publishDate, language)}</span>
            </Box>
          </b>
        </P>
      ) : (
        <Box>
          <P variant="blurb2">
            <b>{formatPublishDate(publishDate, language)}</b>
          </P>
        </Box>
      )}

      <Box my={2}>
        <LayoutCopy content={description} />
      </Box>
      {tags?.length ? (
        <>
          <hr />
          <Box my={2}>
            <P variant="blurb2">
              <b>{t('Tags', { ns: 'videos' })}</b>
            </P>
          </Box>
          <TagList tags={tags} />
        </>
      ) : null}
    </>
  )
}

const StyledHeading = styled(Heading)`
  font-size: 2.8125rem;
  line-height: 1;
  letter-spacing: -0.02em;
  word-break: break-word;
`

const VideoContentSkeleton = () => (
  <Box my={2}>
    <Heading component="h1" variant="h3">
      <Skeleton variant="text" />
    </Heading>
    <P variant="blurb2">
      <Skeleton variant="text" />
    </P>
    <P variant="blurb2">
      <Skeleton variant="text" />
    </P>
    <P variant="blurb2">
      <Skeleton variant="text" />
    </P>
    <P variant="blurb2">
      <Skeleton variant="text" />
    </P>
    <P variant="blurb2">
      <Skeleton variant="text" />
    </P>
    <P variant="blurb2">
      <Skeleton variant="text" />
    </P>
  </Box>
)

export default VideoContent
