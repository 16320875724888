import React from 'react'
import { Router } from '@reach/router'
import { graphql } from 'gatsby'

import VideoTemplate from '../../templates/VideoTemplate'

const VideoRouter = () => {
  return (
    <>
      <Router basepath="/videos">
        <VideoTemplate path="/:id" />
      </Router>
      <Router basepath=":lang/videos">
        <VideoTemplate path="/:id" />
      </Router>
    </>
  )
}

export default VideoRouter

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "videos"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
